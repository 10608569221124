import { Box, Paper, Typography } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { addAssetToElement } from '../../../store/actions/elementActions';
import { getAllAssetsById, getLibraries } from '../../../store/selectors/assetLibrariesSelectors';
import { getAppInfo } from '../../../store/selectors/appSelectors';
import { parseAssetSrc, stringifyAssetSrc } from '../../../util/util';
import { getAllScriptsById } from '../../../store/selectors/scriptLibrariesSelectors';
import { tagsToProps } from '../script/ScriptUtilities';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import InspectableIdentity, { elementToInspectable } from '../common/MuiInspectableIdentity';
import InspectorInterface from '../common/InspectorInterface';
import ItemSocket from '../MuiItemSocket';
import ItemTypes from '../../../features/dragAndDrop/DragDropTypes';
import ScriptsInspector from '../script/MuiScriptsInspector';
import Transform from '../MuiTransform';
import VersionSelector from '../MuiVersionSelector';
import AudioMixingSettings from './MuiAudioMixingSettings';

const ContentElementInspector = ({
  location,
  history,
  assetsById,
  element,
  scene: { id: sceneId },
  onAddAssetToElement,
  onUpdateElement,
  scripts
}) => {
  /**
   * Called when an item should be edited.
   */
  const onEditItem = ({ item, url }) => {
    if (!item) {
      return;
    }

    const pathname = `/i/${url}/${item.id}`;

    // ignore if we're already there
    if (location.pathname === pathname) {
      return;
    }

    const { search } = location;
    history.push({
      pathname,
      search
    });
  };

  const { id: elementId, schema: { strings: { assetSrc = '' } = {} } = {} } = element;

  // get version and id
  const [assetId, assetVersion] = parseAssetSrc(assetSrc);

  // find asset
  const asset = assetsById[assetId];

  // Check if any of the scripts have Phrases
  const [hasPhrases, setHasPhrases] = useState(false);
  useEffect(() => {
    const hasPhrasesInScripts = scripts.some(scriptData => {
      if (typeof scriptData !== 'object') return false;
      const defs = tagsToProps(scriptData.tags);
      return defs.some(def => def.name.startsWith("phrases_"));
    });
    setHasPhrases(hasPhrasesInScripts);
  }, [scripts]);

  return (
    <React.Fragment>
      <Box>
        {/* Basics */}
        <InspectableIdentity
          wrapInPaper
          inspectable={element}
          inspectableTranslator={elementToInspectable}
          onUpdate={onUpdateElement}
        />

        <Transform hideTitle element={element} sceneId={sceneId} />

        {/* Asset. */}
        <Box display="flex" flexDirection="column" mt={1}>
          {/* <Typography variant="h6">Asset</Typography> */}
          {
            <Box display="flex" height={220}>
              <ItemSocket
                item={asset}
                version={asset && assetVersion === -1 ? asset.version : assetVersion}
                type={ItemTypes.ASSET}
                onDrop={(_asset, oldAsset) => onAddAssetToElement({
                  sceneId,
                  element,
                  asset: _asset,
                  oldAsset
                })}
                onEdit={item => onEditItem(item)}
                onRemove={() => onUpdateElement({
                  name: 'assetSrc',
                  value: '',
                  type: ActionSchemaTypes.STRING
                })}
                placeholder={'Drop an asset here.'}
              />
            </Box>
          }
          {asset && (
            <Box mb={1}>
              <Paper square>
                <Box display="flex" py={1} p={1}>
                  <VersionSelector
                    value={assetVersion}
                    max={asset.version}
                    onChange={v => onUpdateElement({
                      name: 'assetSrc',
                      value: stringifyAssetSrc(asset, v),
                      type: ActionSchemaTypes.STRING
                    })}
                  />
                </Box>
              </Paper>
            </Box>
          )}

          {/* Audio Mixing Settings */}
          {(element.schema.bools && element.schema.bools.isPhrasesChild) && (
            <Box display="flex">
              <AudioMixingSettings element={element} onUpdate={onUpdateElement}/>
            </Box>
          )}

        </Box>
      </Box>
      <ScriptsInspector sceneId={sceneId} element={element} asset = {asset} onUpdateElement={onUpdateElement} />
    </React.Fragment>
  );
};

ContentElementInspector.propTypes = {
  ...InspectorInterface,
  app: PropTypes.object.isRequired,
  appId: PropTypes.string.isRequired,
  assetsById: PropTypes.object.isRequired,
  libraries: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  scripts: PropTypes.array.isRequired,

  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state, {element}) => {
  const { app, inspector } = state;
  const { schema } = element;
  const elementScriptsStr = schema && schema.strings && schema.strings.scripts;
  let elementScripts = [];
  try {
    if (typeof elementScriptsStr !== 'undefined') {
      elementScripts = JSON.parse(elementScriptsStr);
    }
  } catch (err) {
    log.error('Could not parse script ids on element: ', err, element);
  }
  const validatedElementScripts = elementScripts.filter(scriptData => typeof scriptData.id === 'string');
  const allScriptsById = getAllScriptsById(state);
  // TODO Apparently validatedElementScripts can contain a script that allScriptsById does not.
  const scripts = validatedElementScripts.map(({ id }) => allScriptsById[id]).filter(value => value);
  const externalScripts = validatedElementScripts.filter(({ id }) => !allScriptsById[id]);

  return {
    app,
    appId: getAppInfo(state).id,
    assetsById: getAllAssetsById(state),
    libraries: getLibraries(state),
    inspector: inspector.content, // TODO Why change?
    scripts,
  };
};

const dispatchMap = {
  onAddAssetToElement: addAssetToElement
};

export default compose(connect(mapStateToProps, dispatchMap), withRouter)(ContentElementInspector);
