import {
  Box,
  Typography,
  ExpansionPanel as Accordion,
  ExpansionPanelSummary as AccordionSummary,
  ExpansionPanelDetails as AccordionDetails,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import BufferedSlider from '../../material-ui/BufferedSlider';
import CheckboxField from '../../material-ui/CheckboxField';
import { createSchemaUpdate } from '../common/inspectorUtils';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import AudioChannleList from '../../../constants/AudioChannelList';
import { updateElement } from '../../../store/actions/inspector/inspectorActions';
import { update } from 'lodash';

const sectionHeader = (title) => (
  <AccordionSummary expandIcon={<ExpandMore />}>
    <Typography variant="h6">{title}</Typography>
  </AccordionSummary>
);

// Builds a slider component for INT type schema variables
const buildSlider = ({ min, max, step, autoMark, schemaKey, element, onUpdate, defaultValue, onUpdateElement, isPhrasesElement, sceneId }) => {
  const onChange = (value) => {
    onUpdate(createSchemaUpdate({ type: ActionSchemaTypes.FLOAT, name: schemaKey, value }));
    // Update the child elements as well if they are phrases children
    if (isPhrasesElement) {
      element.children.forEach(child => {
        onUpdateElement({ sceneId, elementId: child.id, update: createSchemaUpdate({ type: ActionSchemaTypes.FLOAT, name: schemaKey, value }) });
      });
    }
  };
  return (
    <BufferedSlider
      value={(element.schema.floats && element.schema.floats[schemaKey]) || defaultValue}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      autoMark={autoMark}
    />);
};

const AudioMixingSettings = ({ sceneId, element, onUpdate, isPhrasesElement, onUpdateElement }) => {
  return (
    <Accordion style={{ width: '100%' }} defaultExpanded>
      {sectionHeader('Audio Mixing Settings')}
      <AccordionDetails>
        <div style={{ width: '100%' }}>
          {/* Select Channel */}
          <InputLabel variant='outlined'>Audio Channel</InputLabel>
          <Select
            fullWidth
            input={<OutlinedInput />}
            value={(element.schema.strings && element.schema.strings['audio.channel']) || 'none'}
            placeholder='Select Channel'
            onChange={({ target: { value: newValue } }) => {
              onUpdate(createSchemaUpdate({ name: 'audio.channel', value: newValue }));
              if (isPhrasesElement) {
                // Update the child elements as well if they are phrases children
                element.children.forEach(child => {
                  onUpdateElement({ sceneId, elementId: child.id, update: createSchemaUpdate({ name: 'audio.channel', value: newValue }) });
                });
              }
            }}
            variant='outlined'
          >
            {AudioChannleList.map(({ displayName, value }, i) => (
              <MenuItem key={i} value={value}>
                {displayName}
              </MenuItem>
            ))}
          </Select>
          <Box mt={1}>
            {/* Volume */}
            <Box mt={1}>
              <Typography>Volume</Typography>
              {buildSlider({
                min: 0,
                max: 1,
                step: 0.01,
                autoMark: true,
                schemaKey: 'audio.volume',
                element,
                onUpdate,
                defaultValue: 1,
                onUpdateElement,
                isPhrasesElement,
                sceneId
              })}
            </Box>
            {/* Spatial Blend */}
            <Box mt={1}>
              <Typography>Spatial Blend</Typography>
              {buildSlider({
                min: 0,
                max: 1,
                step: 0.01,
                autoMark: true,
                schemaKey: 'audio.spatialBlend',
                element,
                onUpdate,
                defaultValue: 0,
                onUpdateElement,
                isPhrasesElement,
                sceneId
              })}
            </Box>
            {/* Looping */}
            <Box mt={1}>
              <CheckboxField
                label='Looping'
                value={(element.schema.bools && element.schema.bools['audio.loop']) !== undefined 
                  ? element.schema.bools['audio.loop']
                  : false}
                onChange={(_, value) => {
                  onUpdate(createSchemaUpdate({ type: ActionSchemaTypes.BOOL, name: 'audio.loop', value }));
                  // Update the child elements as well if they are phrases children
                  if (isPhrasesElement) {
                    element.children.forEach(child => {
                      onUpdateElement({ sceneId, elementId: child.id, update: createSchemaUpdate({ type: ActionSchemaTypes.BOOL, name: 'audio.loop', value }) });
                    });
                  }
                }}
              />
            </Box>
            {/* Play on enable */}
            <Box mt={1}>
              <CheckboxField
                label='Play on enable'
                value={(element.schema.bools && element.schema.bools['audio.playOnEnable']) !== undefined 
                  ? element.schema.bools['audio.playOnEnable']
                  : true}
                onChange={(_, value) => {
                  onUpdate(createSchemaUpdate({ type: ActionSchemaTypes.BOOL, name: 'audio.playOnEnable', value }))
                  // Update the child elements as well if they are phrases children
                  if (isPhrasesElement) {
                    element.children.forEach(child => {
                      onUpdateElement({ sceneId, elementId: child.id, update: createSchemaUpdate({ type: ActionSchemaTypes.BOOL, name: 'audio.playOnEnable', value }) });
                    });
                  }
                }}
              />
            </Box>
          </Box>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

AudioMixingSettings.propTypes = {
  sceneId: PropTypes.string,
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isPhrasesElement: PropTypes.bool,
  onUpdateElement: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateElement: (params) => dispatch(updateElement(params))
});

// export default AudioMixingSettings;
export default connect(null, mapDispatchToProps)(AudioMixingSettings);
