import { Add } from '@material-ui/icons';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { rgbToHex, hexToRgb } from '../../../util/util';
import { updateAction } from '../../../store/actions/elementActions';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import BaseStandardTextField from '../../material-ui/BaseStandardTextField';
import BufferedInput from '../../material-ui/BufferedInput';
import ColorPicker from '../colorPicker/ColorPicker';
import VectorInput from '../MuiVectorInput';
import txns from '../../../store/actions/TxnManager';

const toInt = (value, fallback) => (Number.isNaN(parseInt(value, 10)) ? fallback : parseInt(value, 10));
const toFloat = (value, fallback) => (Number.isNaN(parseFloat(value)) ? fallback : parseFloat(value));

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2
  }
}));

const DictionaryRenderer = ({
 title, onAdd, dict, renderer
}) => {
  const [newPropName, setNewPropName] = React.useState('');
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h6">{title}</Typography>
      <Box my={1}>
        <BaseStandardTextField
          margin="dense"
          rounded="false"
          placeholder="Property Name"
          value={newPropName}
          onChange={({ target: { value } }) => setNewPropName(value)}
          variant="outlined"
          fullWidth
          multiline
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!!newPropName && newPropName.length > 0 && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (newPropName && !Object.prototype.hasOwnProperty.call(dict, newPropName)) {
                        onAdd(newPropName);
                        setNewPropName('');
                      }
                    }}
                  >
                    <Add />
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
        />
      </Box>

      {dict && Object.keys(dict).length > 0 && (
        <Box overflow="auto" className={clsx(classes.rendererContainer, 'scrollable-y')}>
          <List>
            {Object.keys(dict).map((key, i) => (
              <ListItem className={classes.listItem} disableGutters key={i}>
                {renderer(key, i)}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

DictionaryRenderer.propTypes = {
  title: PropTypes.string,
  dict: PropTypes.object,
  renderer: PropTypes.func,
  onAdd: PropTypes.func
};

const useStyles2 = makeStyles(theme => ({
  labeledInput: {
    width: '100%',
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  textField: {
    marginTop: '2px'
  }
}));

/**
 * WIP class that renders schema controls for debugging.
 */
const SchemaRenderer = (props) => {
  const {
    element: {
      id, schema: {
        strings = {}, ints = {}, floats = {}, bools = {}, vectors = {}, colors = {}, phrases = {}
      } = {}
    },
    scene: { id: sceneId }
  } = props;

  const classes = useStyles2();

  const updateKey = (type, key, value) => txns.request(sceneId, updateAction(id, type, key, value));

  return (
    <Box flex={1}>
      <DictionaryRenderer
        title="Strings"
        dict={strings}
        renderer={key => (
          <BufferedInput
            margin="dense"
            input={TextField}
            className={classes.textField}
            label={key}
            placeholder="Enter Value"
            value={strings[key]}
            fullWidth
            multiline
            onChange={({ target: { value } }) => updateKey(ActionSchemaTypes.STRING, key, value)}
            variant="outlined"
          />
        )}
        onAdd={key => updateKey(ActionSchemaTypes.STRING, key, 'value')}
      />
      <DictionaryRenderer
        title="Integers"
        dict={ints}
        renderer={key => (
          <BufferedInput
            margin="dense"
            input={TextField}
            className={classes.textField}
            label={key}
            placeholder="Enter Number"
            value={ints[key]}
            fullWidth
            multiline
            onChange={({ target: { value } }) => {
              const formatted = toInt(value, ints[key]);
              if (formatted === ints[key]) {
                return;
              }

              updateKey(ActionSchemaTypes.INT, key, formatted);
            }}
            variant="outlined"
          />
        )}
        onAdd={key => updateKey(ActionSchemaTypes.INT, key, 0)}
      />
      <DictionaryRenderer
        title="Number"
        dict={floats}
        renderer={key => (
          <BufferedInput
            margin="dense"
            input={TextField}
            label={key}
            placeholder="Enter Number"
            value={floats[key]}
            fullWidth
            multiline
            onChange={({ target: { value } }) => {
              const formatted = toFloat(value, floats[key]);
              if (formatted === floats[key]) {
                return;
              }

              updateKey(ActionSchemaTypes.FLOAT, key, formatted);
            }}
            variant="outlined"
          />
        )}
        onAdd={key => updateKey(ActionSchemaTypes.FLOAT, key, 0.0)}
      />
      <DictionaryRenderer
        title="Booleans"
        dict={bools}
        renderer={key => (
          <FormControlLabel
            className={classes.labeledInput}
            labelPlacement="start"
            control={
              <Checkbox
                checked={bools[key]}
                onChange={evt => updateKey(ActionSchemaTypes.BOOL, key, evt.target.checked)}
              />
            }
            label={key}
          />
        )}
        onAdd={key => updateKey(ActionSchemaTypes.BOOL, key, true)}
      />
      <DictionaryRenderer
        title="Vectors"
        dict={vectors}
        renderer={key => (
          <FormControl key={key}>
            <InputLabel>{key}</InputLabel>
            <VectorInput value={vectors[key]} onChange={val => updateKey(ActionSchemaTypes.VEC3, key, val)} />
          </FormControl>
        )}
        onAdd={key => updateKey(ActionSchemaTypes.VEC3, key, {
            x: 0,
            y: 0,
            z: 0
          })
        }
      />
      <DictionaryRenderer
        title="Colors"
        dict={colors}
        renderer={key => (
          <FormControlLabel
            className={classes.labeledInput}
            labelPlacement="start"
            control={
              <ColorPicker
                color={rgbToHex(colors[key])}
                onChange={color => updateKey(ActionSchemaTypes.COL4, key, hexToRgb(color))}
              />
            }
            label={key}
          />
        )}
        onAdd={key => updateKey(ActionSchemaTypes.COL4, key, {
            r: 1,
            g: 1,
            b: 1,
            a: 1
          })
        }
      />
      </Box>
  );
};

SchemaRenderer.propTypes = {
  element: PropTypes.object.isRequired,
  scene: PropTypes.object.isRequired
};

export default SchemaRenderer;
