import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, DialogTitle, DialogContent, Dialog, Button } from '@material-ui/core';
import { useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import FileCopy from '@material-ui/icons/FileCopy';
import { getAppInfo } from '../../store/selectors/appSelectors';
import { set } from 'lodash';
import BaseButton from '../material-ui/BaseButton';

const url = 'https://exp-scraping-server.azurewebsites.net';
// const url = 'http://127.0.0.1:5000';

function ExpHierarchy({ appId }) {
  const [showExpHierarchy, setShowExpHierarchy] = useState(false);
  const [expHierarchy, setExpHierarchy] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const getExpHierarchy = () => {
    console.log('Getting experience hierarchy for ... ', appId);
    fetch(`${url}/get_exp_tree?root=${appId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'processing') {
          setExpHierarchy(data.message);
        } else {
          const message = JSON.parse(data.message);
          setExpHierarchy(message);
        }
        if (data.last_updated) {
          const date = new Date(data.last_updated);
          const localTime = date.toLocaleString();
          setLastUpdated(localTime);
        }
      })
      .catch((error) => console.error(error));
  };

  const refreshExpHierarchy = () => {
    console.log('Refreshing experience hierarchy ... ', appId);
    fetch(`${url}/update_exp_tree?root=${appId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'processing') {
          setExpHierarchy(data.message);
          setLastUpdated(null);
        } else {
          const message = JSON.parse(data.message);
          setExpHierarchy(message);
        }
      })
      .catch((error) => console.error(error));
  };


  const renderHierarchy = (hierarchy) => {
    if (!hierarchy) return null;

    return Object.keys(hierarchy).map((key) => (
      <Box key={key} ml={2} mt={1}>
        <Typography variant="subtitle1">{key}</Typography>
        <Box ml={2}>
          {hierarchy[key] && hierarchy[key].map((value, index) => (
            <Typography key={index} variant="body2">
              {value}
            </Typography>
          ))}
        </Box>
      </Box>
    ));
  };

  const handleOpenModal = () => {
    getExpHierarchy();
    setShowExpHierarchy(true);
  };

  const handleCloseModal = () => {
    setShowExpHierarchy(false);
  };

  return (
    <Box>
      <Box my={1}>
        <Typography variant="h6">Experience Hierarchy</Typography>
        <BaseButton
          color="secondary"
          size="large"
          variant="contained"
          onClick={handleOpenModal}
        >
          Experience Hierarchy
        </BaseButton>
      </Box>
      <Dialog open={showExpHierarchy} onClose={handleCloseModal} fullWidth PaperProps={{ style: { height: '80vh' } }}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Experience Hierarchy</Typography>
            <Button
              onClick={getExpHierarchy}
              color="primary"
              variant="contained"
              size="small"
              style={{ marginLeft: 20 }}
            >
              Refresh
            </Button>
            <Button
              onClick={() => refreshExpHierarchy()}
              color="primary"
              variant="contained"
              size="small"
              style={{ marginLeft: 20 }}
            >
              Update
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => navigator.clipboard.writeText(JSON.stringify(expHierarchy))}
              color="primary"
              variant="text"
              size="small"
              startIcon={<FileCopy />}
              style={{ marginLeft: 20 }}
            />
          </Box>
        </Box>
        {lastUpdated && (
          <Typography style={{ marginTop: 4 }}>{`Last Updated ${lastUpdated} - Click on 'Update' to reflect newer changes!`}</Typography>
        )}
      </DialogTitle>
        <DialogContent>
          <Box
            style={{
              color: 'white',
            }}
          >
            {typeof expHierarchy === 'object'
              ? renderHierarchy(expHierarchy)
              : expHierarchy}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

ExpHierarchy.propTypes = {
  appId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  appId: getAppInfo(state).id,
});

export default connect(mapStateToProps)(ExpHierarchy);