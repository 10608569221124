import {
  Typography, InputLabel, Select, OutlinedInput, MenuItem, Box
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getScene } from '../../store/selectors/scenesSelectors';
import { setRenderingSetting } from '../../store/actions/sceneActions';
import { updateAction } from '../../store/actions/elementActions';
import txns from '../../store/actions/TxnManager';
import BufferedSlider from '../material-ui/BufferedSlider';
import AudioChannelList from '../../constants/AudioChannelList';
import SchemaContainersByType from '../../constants/SchemaContainersByType';
import ActionSchemaTypes from '../../constants/ActionSchemaTypes';

// Builds a slider component for a specific parameter / config combination
const buildSlider = ({ min, max, step, autoMark, value, schemaKey, scene, onUpdateSceneSetting }) => {
  const onChange = (value) => {
    onUpdateSceneSetting({
      sceneId: scene.id,
      schemaType: SchemaContainersByType.float,
      key: schemaKey,
      value
    });
  };
  return (
    <BufferedSlider
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      autoMark={autoMark}
    />);
};

const AudioSettings = ({ scene, onUpdateSceneSetting }) => {
// const AudioSettings = () => {
  const [channel, setChannel] = useState(null);
  return (
    <div style={{ width: '100%' }}>
      <Box mt={1} >
        {/* Audio Channel Selection */}
        <Box mt={1}>
          <InputLabel variant='outlined'>Select Audio Channel</InputLabel>
          <Select
            fullWidth
            input={<OutlinedInput />}
            value={channel}
            onChange={({ target: { value: newValue } }) => setChannel(newValue)}
            variant='outlined'
          >
            {AudioChannelList.map(({ displayName, value }, i) => (
              value !== 'none' && (
                <MenuItem key={i} value={value}>
                  {displayName}
                </MenuItem>
              )
            ))}
          </Select>
        </Box>

        {channel !== null && (
          <Box>
            <Box mt={1}>
              <Typography variant='subtitle1'>Channel Volume</Typography>
              {buildSlider({
                min: -80,
                max: 20,
                step: 0.5,
                autoMark: true,
                value: (scene.elements.schema.floats && scene.elements.schema.floats[`audio.${channel}.volume`]) || 0,
                schemaKey: `audio.${channel}.volume`,
                scene,
                onUpdateSceneSetting
              })}
            </Box>

            {channel !== 'master' && (
              <Box>
                <Box mt={1}>
                  <Typography variant='subtitle1'>Channel Compression Threshold</Typography>
                  {buildSlider({
                    min: -60,
                    max: 0,
                    step: 0.5,
                    autoMark: true,
                    value: (scene.elements.schema.floats && scene.elements.schema.floats[`audio.${channel}.compression.threshold`]) || 0,
                    schemaKey: `audio.${channel}.compression.threshold`,
                    scene,
                    onUpdateSceneSetting
                  })}
                </Box>
                <Box mt={1}>
                  <Typography variant='subtitle1'>Channel Compression Gain</Typography>
                  {buildSlider({
                    min: 0,
                    max: 30,
                    step: 0.5,
                    autoMark: true,
                    value: (scene.elements.schema.floats && scene.elements.schema.floats[`audio.${channel}.compression.gain`]) || 0,
                    schemaKey: `audio.${channel}.compression.gain`,
                    scene,
                    onUpdateSceneSetting
                  })}
                </Box>
              </Box>
            )}


          </Box>
        )}
      </Box>

    </div>
  );
};

// map state to props
const mapStoreToProps = store => ({
  scene: getScene(store)
});

// map dispatch to props
const mapDispatchToProps = (dispatch) => ({
  onUpdateSceneSetting: ({
    sceneId, schemaType, key, value
  }) => {
    dispatch(setRenderingSetting({
      sceneId,
      schemaType,
      key,
      value
    }));

    let actionType;
    if (schemaType === SchemaContainersByType.float) {
      actionType = ActionSchemaTypes.FLOAT;
    } else if (schemaType === SchemaContainersByType.string) {
      actionType = ActionSchemaTypes.STRING;
    }

    txns.request(sceneId, updateAction('root', actionType, key, value, value));
  },
});

// Prop validation
AudioSettings.propTypes = {
  scene: PropTypes.object.isRequired,
  onUpdateSceneSetting: PropTypes.func.isRequired
};

export default connect(mapStoreToProps, mapDispatchToProps)(AudioSettings);
