import { Box, Typography } from '@material-ui/core';
import { Shortcuts } from 'react-shortcuts/lib';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { downloadscript } from '../../store/actions/scriptActions';
import { getAllScriptsById, getLibraries } from '../../store/selectors/scriptLibrariesSelectors';
import { tagsToType, updateTagsFromSource, scriptUrl } from '../../components/inspector/script/ScriptUtilities';
import { updateScript, deleteScript } from '../../store/actions/inspector/inspectorActions';
import { getSelectedScriptCategoryName } from '../../store/selectors/uiSelectors';
import ScriptTextEditor from './ScriptTextEditor';
import ScriptToolbar from './MuiScriptToolbar';
import LibraryTypes from '../../constants/LibraryTypes';

const initialState = {
  autoSave: false,
  isLoading: false,
  message: 'No script selected.',
  source: '',
  name: ''
};

/**
 * For editing scripts.
 */
class ScriptEditor extends React.PureComponent {
  editor = null;

  autoSaveTimeoutId = -1;

  /**
   * Constructor.
   */
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.loadScript();
  }

  componentDidUpdate(prevProps) {
    const { scriptId } = prevProps;
    const { scriptId: newScriptId } = this.props;
    const { isLoading } = this.state;

    if (newScriptId !== scriptId && !isLoading) {
      this.loadScript();
    }
  }

  /**
   * Called after the editor is first mounted.
   *
   * @param      {<type>}  editor  The editor
   */
  onMountEditor(editor) {
    this.editor = editor;
    this.editor.session.on('change', () => this.onEditorUpdate());
  }

  /**
   * Called after the editor is destroyed.
   */
  onUnmountEditor() {
    this.editor = null;
  }

  /**
   * Loads the source of a script.
   *
   * @param scriptId - the script to load
   */
  async loadScript() {
    const { scriptsById, onLoadScript, scriptId } = this.props;
    const script = scriptsById[scriptId];

    if (script) {
      this.setState({
        script,
        source: '',
        name: '',
        isLoading: true,
        message: 'Loading...'
      });
      try {
        const result = await onLoadScript(script.id, scriptUrl(script.uri));
        this.setState({ name: script.name, source: result, message: '' });
      } catch (error) {
        this.setState({ message: error });
      } finally {
        this.setState({ isLoading: false });
      }
    } else if (scriptId) {
      this.setState({ message: `No script by id '${scriptId}' found.` });
    }
  }

  /**
   * Toggles auto-save function.
   */
  toggleAutoSave() {
    clearTimeout(this.autoSaveTimeoutId);

    this.setState({ autoSave: !this.state.autoSave });
  }

  /**
   * Saves the script.
   */
  save() {
    const { onUpdateScript } = this.props;
    const {
      script: { id, tags }
    } = this.state;
    const source = this.editor.getValue();

    onUpdateScript({
      id,
      source: btoa(source),
      tags: updateTagsFromSource(tags, source)
    });
  }

  /**
   * Called when there's an update.
   */
  onEditorUpdate() {
    const { autoSave } = this.state;

    if (autoSave) {
      clearTimeout(this.autoSaveTimeoutId);
      this.autoSaveTimeoutId = setTimeout(() => this.save(), 1000);
    }
  }

  /**
   * Renders controls.
   */
  render() {
    const { onDeleteScript, onClose, libraries, scriptId} = this.props;

    const {
 autoSave, script, source, name, message
} = this.state;

    return (
      <Box display="flex" flex={1} flexDirection="column">
        <Shortcuts
          className="flex-container-column"
          name="SCRIPT"
          handler={(action, event) => {
            event.preventDefault();
            switch (action) {
              case 'SAVE': {
                this.save();
                break;
              }

              default:
                break;
            }
          }}
        >
          <React.Fragment>
            <ScriptToolbar
              scriptId={scriptId}
              enabled={!!source}
              name={name}
              autoSave={autoSave}
              onAutoSaveChanged={() => this.toggleAutoSave()}
              onSave={() => this.save()}
              onClose={() => {
                this.setState(initialState);
                onClose();
              }}
              onDelete={async () => {
                await onDeleteScript({ id: script.id });
                this.setState(initialState);
                onClose();
              }}
              onTemplate={template => this.editor.setValue(template)}
              inPublicScripts = {libraries[LibraryTypes.PUBLIC][scriptId]}
            />

            {message && (
              <Box display="flex" flex={1} justifyContent="center" alignItems="center">
                <Typography>{message}</Typography>
              </Box>
            )}

            {source && (
              <ScriptTextEditor
                source={source}
                inPublicScripts = {libraries[LibraryTypes.PUBLIC][scriptId]}
                type={tagsToType(script.tags)}
                onMountEditor={this.onMountEditor.bind(this)}
                onUnmountEditor={this.onUnmountEditor.bind(this)}
              />
            )}
          </React.Fragment>
        </Shortcuts>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  scriptsById: getAllScriptsById(state),
  libraries: getLibraries(state)
});

const dispatchMap = {
  onLoadScript: downloadscript,
  onUpdateScript: updateScript,
  onDeleteScript: deleteScript
};

ScriptEditor.propTypes = {
  scriptId: PropTypes.string,
  onClose: PropTypes.func.isRequired,

  // connect
  libraries: PropTypes.object.isRequired,
  selectedScriptCategoryName: PropTypes.string.isRequired,
  scriptsById: PropTypes.object.isRequired,
  onLoadScript: PropTypes.func.isRequired,
  onUpdateScript: PropTypes.func.isRequired,
  onDeleteScript: PropTypes.func.isRequired
};

export default compose(connect(mapStateToProps, dispatchMap))(ScriptEditor);
