import {
 Box, Paper, TextField, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { createSchemaUpdate } from './inspectorUtils';
import BufferedInput from '../../material-ui/BufferedInput';

const InspectableIdentity = ({
  title,
  inspectable: _inspectable,
  inspectableTranslator,
  onUpdate,
  classes,
  wrapInPaper
}) => {
  const inspectable = inspectableTranslator(_inspectable);

  const mainContent = (
    <div>
      {title && <Typography variant="h6">{title}</Typography>}
      <div className={classes.inputFieldsContainer}>
        <BufferedInput
          input={TextField}
          label="Name"
          placeholder="Enter Name"
          value={inspectable.name}
          fullWidth
          multiline
          onChange={({ target: { value } }) => {
            onUpdate(createSchemaUpdate({ name: 'name', value }));
          }}
          variant="outlined"
        />
        <BufferedInput
          input={TextField}
          label="Description"
          placeholder="Enter Description"
          value={inspectable.description}
          fullWidth
          multiline
          onChange={({ target: { value } }) => {
            onUpdate(createSchemaUpdate({ name: 'description', value }));
          }}
          variant="outlined"
        />
      </div>
    </div>
  );

  return wrapInPaper ? (
    <Paper square>
      <Box pt={1} px={1} mb={1}>
        {mainContent}
      </Box>
    </Paper>
  ) : (
    mainContent
  );
};

InspectableIdentity.propTypes = {
  title: PropTypes.string,
  inspectable: PropTypes.object,
  inspectableTranslator: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  wrapInPaper: PropTypes.bool
};

InspectableIdentity.defaultProps = {
  // title: 'Identity',
  inspectable: { name: '', description: '' },
  inspectableTranslator: inspectable => inspectable
};

export const elementToInspectable = ({ schema: { strings: { name = '', description = '' } = {} } = {} }) => ({
  name,
  description
});

export default withStyles(theme => ({
  inputFieldsContainer: {
    // marginTop: theme.spacing(1),
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }
}))(InspectableIdentity);
