import { Add } from '@material-ui/icons';
import {
    Box, Checkbox, FormControlLabel, FormControl, IconButton, InputLabel, Select, TextField, Typography, CircularProgress
} from '@material-ui/core';
import AudioPlayer from 'material-ui-audio-player';
import Button from '@mui/material/Button';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import uuid from 'uuid';

import { getScenes } from '../../../store/selectors/scenesSelectors';
import ElementTypes from '../../../constants/ElementTypes';
import { log } from '../../../util/log';
import ActionSchemaTypes from '../../../constants/ActionSchemaTypes';
import GenericChooser from '../../common/MuiGenericChooser';
import { getAllAssetsById } from '../../../store/selectors/assetLibrariesSelectors';
import { getAllScripts } from '../../../store/selectors/scriptLibrariesSelectors';
import { addAssetToElement, createFullElementAction } from '../../../store/actions/elementActions';
import txns from '../../../store/actions/TxnManager';
import { getAppInfo } from '../../../store/selectors/appSelectors';
import { elementById, someElement } from '../../../util/elementHelpers';
import ItemTypes from '../../../features/dragAndDrop/DragDropTypes';
import { validate } from 'validate.js';
import { MenuItem } from 'react-bootstrap';
import { create, mapValues } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import AudioMixingSettings from '../element/MuiAudioMixingSettings';
import { platforms } from '../../../store/reducers/scenesReducer';
import playmodeLauncher from '../../../store/actions/PlaymodeLauncher';
import undoManager from '../../../store/actions/UndoManager';
import { createSchemaUpdate } from '../common/inspectorUtils';

// material-ui-audio-player requires using makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

class CustomTextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: undefined,
      delayInputTimeoutId: null,
      assetIdList: ""
    };
  }

  handleChange = (e) => {
    this.setState({ temp: e.target.value }, () => {
      if (this.state.delayInputTimeoutId) {
        clearTimeout(this.state.delayInputTimeoutId);
      }
      const tempValue = this.state.temp;
      const delayInput = setTimeout(() => {
        if (tempValue !== undefined) {
          this.props.onCommit(this.props.handleChange(tempValue));
        }
      }, 500);
      this.setState({ delayInputTimeoutId: delayInput });
    });
  }

  render() {
    const { ...props } = this.props;
    return (
      <TextField
        {...props}
        value={this.state.temp === "" ? "" : this.state.temp || this.props.value}
        onFocus={() => this.setState({ temp: this.props.value })}
        onChange={this.handleChange}
        onBlur={() => {
          const tempValue = this.state.temp;
          this.setState({ temp: undefined });
          if (tempValue !== undefined) {
            const result = this.props.handleChange(tempValue);
            this.props.onCommit(result);
          }
        }}
      />
    );
  }
}

CustomTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onCommit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

// Function to get the duration of an audio file from a URL using async/await
async function getAudioDuration(url) {
  const audio = new Audio(url);

  // Return a promise that resolves with the audio duration
  return new Promise((resolve, reject) => {
    audio.addEventListener('loadedmetadata', () => {
      resolve(audio.duration);
    });

    audio.addEventListener('error', () => {
      reject(new Error('Error loading audio'));
    });

    // Trigger loading of the audio metadata
    audio.load();
  });
}

const phraseHasSpeech = phrase => Object.values(phrase.localizations).some(l => l.url);
const lang_codes = {
  English:"en", French: "fr", German: "de", Hindi: "hi", Italian: "it", Japanese: "ja", Spanish: "es", "Spanish (Mexico)": "es-mx"
};
class VariableList extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    element: PropTypes.object.isRequired,
    sceneId: PropTypes.string.isRequired,
    scenes: PropTypes.arrayOf(PropTypes.object).isRequired,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    classes: PropTypes.object.isRequired,
    assets: PropTypes.object.isRequired,
    findOrCreateLocalizedPhraseElement: PropTypes.func.isRequired,
    createAssetElement: PropTypes.func.isRequired,
    scripts: PropTypes.arrayOf(PropTypes.object).isRequired,
    onUpdateElement: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      valuesByFieldName: this.constructor.createCache(props),
      lastEdited: null,
      voices: [],
      phraseName: "",
      selectedPhrase: null,
      isLoading: false,
    };
    this.elementsRef = React.createRef();
    this.iconButtonRef = React.createRef();
    this.assetsRef = React.createRef();
  }

  getVoices() {
    const req = new XMLHttpRequest();
    this.setState({
      voices: ["Ray"]
    });
    req.onload = () => {
      const arraybuffer = req.response; // not responseText
      const voices = JSON.parse(arraybuffer);
      this.setState({
        voices
      });
    };
    req.open("GET", "https://enklu-gen-flask.azurewebsites.net/voices");
    req.setRequestHeader('Content-type', 'application/json');
    req.responseType = "text";
    req.send();
  }

  componentDidMount() {
    this.getVoices();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO Check for changes.
    this.setState({
      valuesByFieldName: this.constructor.createCache(nextProps)
    });
  }

  static createCache({ fields }) {
    // TODO This should happen in a selector.
    const valuesByFieldName = fields.reduce(
      (accum, { name, value }) => ({
        ...accum,
        [name]: value
      }),
      {}
    );

    return valuesByFieldName;
  }


  render() {
    const { valuesByFieldName } = this.state;

    const {
      classes, appId, sceneId, element, findOrCreateLocalizedPhraseElement, createAssetElement, scenes : [scene], assets, scripts, onUpdateElement
    } = this.props;

    this.elementsRef.current = scene.elements;
    this.assetsRef.current = assets;
    const phrasesPrefix = "phrases_";
    const generatingMessage = "Generating, please wait...";
    const invalidSpeechURL = "https://no.url";
    const phrasesIsGenerating = phrases => phrases.text === generatingMessage;
    const phrasesSpeechNotAvailable = phrases => !phrases.selectedPhrase || !phrases.phrases[phrases.selectedPhrase].localizations || !phrases.phrases[phrases.selectedPhrase].localizations[phrases.language] || !Object.prototype.hasOwnProperty.call(phrases.phrases[phrases.selectedPhrase].localizations[phrases.language], "url") || phrases.phrases[phrases.selectedPhrase].localizations[phrases.language].url === "";


    // TODO This is ridiculous and should be refactored.
    const renderers = this.props.fields.map(({ type, name, onCommit }, index) => {
      const updatePhrases = (rawName, phrases, shouldCommit = true, extras = {}) => {
        this.setState({
          lastEdited: name,
          valuesByFieldName: {
            ...valuesByFieldName,
            [rawName]: JSON.stringify(phrases),
          },
          ...extras
        });
        if (shouldCommit) onCommit(JSON.stringify(phrases));
      };

      const generateText = async (phrases, phrase) => {
        let { prompt } = phrase;
        if (!prompt && phrase.localizations.English.text && phrases.language !== "English") {
          prompt = `Translate the following statement literally to ${phrases.language} without embellishment. Please keep your translation about the same length with the same style of capitalization as the original: ${phrase.localizations.English.text}.`;
        }
        const response = await fetch("https://enklu-gen-flask.azurewebsites.net/textgen", {
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body:  JSON.stringify({ query: prompt, character_description: phrases.charDes, language: phrases.language ? phrases.language : "English" })
        });
        // TODO: Why not text?
        return await response.text();
      };

      const generateAndUpdateText = async (rawName, phrases, phraseName) => {
        const phrase = phrases.phrases[phraseName];
        updatePhrases(rawName, { ...phrases, text: generatingMessage, url: invalidSpeechURL }, false, { shouldPlayAudio: false });
        const text = await generateText(phrases, phrase);
        phrase.localizations[phrases.language] = {
          ...phrase.localizations[phrases.language],
          text,
          textIsStale: false,
          ...(phraseHasSpeech(phrase) && { urlIsStale: true, audioAssetIsStale: true })
        };
        updatePhrases(rawName, phrases);
      };

      const generateSpeech = async (phrases, phraseName) => {
        const phrase = phrases.phrases[phraseName];
        const localization = phrase.localizations[phrases.language];
        const response = await fetch("https://enklu-gen-flask.azurewebsites.net/audio", {
          method: "POST",
          headers: {
            "Content-Type" : "application/json"
          },
          body:  JSON.stringify({
            query: localization.text,
            voice: phrases.voice,
            filename: `${lang_codes[phrases.language]}~${phraseName}~${appId}`
          })
        });
        // TODO: Why not text? Add error handling
        return await response.text();
      };

      const generateAndUpdateSpeech = async (rawName, phrases, phraseName) => {
        const phrase = phrases.phrases[phraseName];
        updatePhrases(rawName, { ...phrases, url: invalidSpeechURL, urlIsLoading: true });
        let url = await generateSpeech(phrases, phraseName, false);
        // Assuming the URL is in the format of "https://s3.amazonaws.com/enklu.audio/${phraseName}.mp3"
        // TODO: This is a hack to encode the filename in the URL. It should be done on the server side.
        const url_split = url.split('/');
        const encodedFilename = encodeURIComponent(url_split[url_split.length - 1]);
        url_split[url_split.length - 1] = encodedFilename;
        url = url_split.join('/');
        const duration = await getAudioDuration(url);

        phrase.localizations[phrases.language] = {
          ...phrase.localizations[phrases.language],
          url,
          duration,
          urlIsStale: false,
          audioAssetIsStale: true
        };
        updatePhrases(rawName, { ...phrases, url, urlIsLoading: false });
      };

      const uploadAsset = async (phrases, phraseName) => {
        const oldAudioId = phrases.phrases[phraseName].localizations[phrases.language].audioID;
        const encodedPhraseName = encodeURIComponent(phraseName);
        const params = {
          looping : false,
          streaming : true,
          "3D" : true,
          range : 2,
          ...(oldAudioId && { replacementAssetId: oldAudioId })
        };
        const response = await fetch(`https://s3.amazonaws.com/enklu.audio/${lang_codes[phrases.language]}~${encodedPhraseName}~${appId}.mp3`);
        const blob = await response.blob();
        const file = new File([blob], `${lang_codes[phrases.language]}~${phraseName}.mp3`, { type: 'audio/mp3' });

        return new Promise((resolve, reject) => {
        const uploader = new FineUploaderTraditional({
          options: {
            autoUpload: true,
            request: {
              endpoint: `${window.env.assetBaseUrl}/v1/asset`,
              customHeaders: {
                Authorization: `Bearer ${io.sails.token}`,
                app: appId
              },
              method: 'POST',
            },
            callbacks: {
              onComplete: async (id, assetName, { body, success } /* xhr */) => {
                if (success) {
                  resolve(body.id);
                } else {
                  // TODO: new action for failed uploads
                  reject(body);
                }
              }
            }
          }
        });

        uploader.methods.addFiles(file, params);
        });
      };

      const generateAndUploadAsset = async (rawName, phrases, phraseName) => {
        const phrase = phrases.phrases[phraseName];
        const audioId = await uploadAsset(phrases, phraseName);
        phrase.localizations[phrases.language] = {
          ...phrase.localizations[phrases.language],
          audioAssetIsStale: false,
          audioID: audioId
        };
        updatePhrases(rawName, phrases);
      };

      const rawName = name;
      let adjustedType = type.toLowerCase();
      if (name && name.startsWith(phrasesPrefix)) {
        name = name.slice(phrasesPrefix.length);
        adjustedType = ActionSchemaTypes.PHRASES;
      }
      if (name && name.startsWith("audioMixing_")) {
        name = name.slice("audioMixing_".length);
        adjustedType = ActionSchemaTypes.AUDIOMIXING;
      }
      const displayName = name ? `${name.charAt(0).toUpperCase()}${name.slice(1)}` : '';
      switch (adjustedType) {
        case ActionSchemaTypes.AUDIOMIXING: {
          return (
            <Box display="flex">
              <AudioMixingSettings sceneId={sceneId} element={element} onUpdate={onUpdateElement} isPhrasesElement={element.schema.bools.isPhrasesElement} />
            </Box>
          );
        }
        case ActionSchemaTypes.STRING: {
          return (
            <CustomTextField
              key={index}
              label={displayName}
              value={valuesByFieldName[name]}
              fullWidth
              multiline
              handleChange={(temp) => temp}
              onCommit={onCommit}
              variant="outlined"
            />
          );
        }
        case ActionSchemaTypes.INT: {
          return (
            <CustomTextField
              fullWidth
              multiline
              key={index}
              label={displayName}
              value={valuesByFieldName[name]}
              handleChange={(temp) => parseInt(temp, 10)}
              onCommit={onCommit}
            />
          );
        }
        case ActionSchemaTypes.FLOAT: {
          return (
            <CustomTextField
              key={index}
              label={displayName}
              type={'number'}
              step={'0.1'}
              value={valuesByFieldName[name]}
              fullWidth
              multiline
              handleChange={(temp) => parseFloat(temp)}
              onCommit={onCommit}
              variant="outlined"
            />
          );
        }
        case ActionSchemaTypes.BOOL: {
          return (
            <FormControlLabel
              key={index}
              className={classes.labeledInput}
              control={<Checkbox style={{ backgroundColor: 'transparent' }} disableRipple />}
              labelPlacement="start"
              checked={valuesByFieldName[name]}
              onChange={({ target: { checked } }) => {
                this.setState({
                  lastEdited: name,
                  valuesByFieldName: {
                    ...valuesByFieldName,
                    [name]: checked
                  }
                });
                onCommit(checked);
              }}
              label={displayName}
            />
          );
        }

        case ActionSchemaTypes.PHRASES: {
          let { shouldPlayAudio, voices } = this.state;
          let languages = ["English", "Spanish", "Spanish (Mexico)", "Hindi", "French", "German", "Italian", "Japanese"];
          shouldPlayAudio = shouldPlayAudio || false;
          const defaultValues = {
            charDes: "c",
            prompt: "p",
            text: "t",
            voice: "Ray",
            phrases: []
          };
          voices = voices || [];
          console.log(`valuesByFieldName: ${JSON.stringify(valuesByFieldName)}`);
          const phrases = JSON.parse(valuesByFieldName[rawName]);
          const generating = phrasesIsGenerating(phrases);
          const speechUnavailable = phrasesSpeechNotAvailable(phrases);
          return (
            <div>
              <Typography>{displayName}</Typography>
              <FormControl className={classes.formStyle}>
                <div className={classes.formElementsSpacing}>
                <CustomTextField
                  key={`charDes{index}`}
                  label="Character Description"
                  disabled={generating}
                  variant="outlined"
                  multiline
                  fullwidth
                  value={ phrases.charDes || "" }
                  handleChange={(temp) => {
                    const oldValue = JSON.parse(this.state.valuesByFieldName[rawName]);
                    return JSON.stringify({ ...oldValue, charDes: temp });
                  }}
                  onCommit={onCommit}
                  className={classes.formElementsSpacing}
                />

                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch" mt={1} gap={2}>
                <Button variant="contained" onClick={async () => {
                  this.setState({ isLoading: true });
                  // Create element for every existing phrase in the phrases (for which audioId exists)
                  await Promise.all(Object.entries(phrases.phrases).map(async ([phraseName, phraseData]) => {
                    if (!phrases.language) phrases.language = "English";
                    if (!phraseData.localizations[phrases.language]) {
                      phraseData.localizations[phrases.language] = {
                        text:"", audioID:"", id: uuid(), textIsStale: true
                      };
                    }
                    // Always getting via a thunk makes sure we are never looking at stale data
                    const localizationThunk = () => phraseData.localizations[phrases.language];
                    if (!localizationThunk().id) localizationThunk().id = uuid();
                    if (localizationThunk().textIsStale) await generateAndUpdateText(rawName, phrases, phraseName);
                    if (!phraseHasSpeech(phraseData)) return;
                    if (localizationThunk().urlIsStale) await generateAndUpdateSpeech(rawName, phrases, phraseName);
                    // TODO: Remove once legacy experiences are converted
                    if (localizationThunk().url && !localizationThunk().duration) {
                      phraseData.localizations[phrases.language] = {
                        ...localizationThunk(),
                        duration : await getAudioDuration(localizationThunk().url)
                      };
                    }
                    if (!localizationThunk().audioID || localizationThunk().audioAssetIsStale) await generateAndUploadAsset(rawName, phrases, phraseName);
                    await findOrCreateLocalizedPhraseElement({
                      phrases,
                      phraseName,
                      language: phrases.language,
                      elementsRef: this.elementsRef,
                      assets,
                      assetsRef: this.assetsRef,
                      onUpdateElement
                    });
                  }));
                  this.setState({ isLoading: false });
                }}
                  className={classes.formElementsSpacing}>Publish</Button>
              
                {/* button to update only the selected phrase instead of all the phrase (which is what publish does) */}
                <Button variant="contained" onClick={async () => {
                  this.setState({ isLoading: true });
                  const phraseName = phrases.selectedPhrase;
                  if (!phrases.language) phrases.language = "English";
                  if (!phrases.phrases[phraseName].localizations[phrases.language]) {
                    phrases.phrases[phraseName].localizations[phrases.language] = {
                      text:"", audioID:"", id: uuid(), textIsStale: true
                    };
                  }
                  // Always getting via a thunk makes sure we are never looking at stale data
                  const localizationThunk = () => phrases.phrases[phraseName].localizations[phrases.language];
                  if (!localizationThunk().id) localizationThunk().id = uuid();
                  if (localizationThunk().textIsStale) await generateAndUpdateText(rawName, phrases, phraseName);
                  if (!phraseHasSpeech(phrases.phrases[phraseName])) return;
                  if (localizationThunk().urlIsStale) await generateAndUpdateSpeech(rawName, phrases, phraseName);

                  if (!localizationThunk().audioID || localizationThunk().audioAssetIsStale) await generateAndUploadAsset(rawName, phrases, phraseName);
                  await findOrCreateLocalizedPhraseElement({
                    phrases,
                    phraseName,
                    language: phrases.language,
                    elementsRef: this.elementsRef,
                    assets,
                    assetsRef: this.assetsRef,
                    onUpdateElement
                  });
                  this.setState({ isLoading: false });
                }}
                  className={classes.formElementsSpacing}>Update</Button>
                </Box>

                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch" mt={1}>
                  <Box width="50%">
                    <Select id="voice-select" label="Choose Voice" className={classes.formElementsSpacing} value={phrases.voice}
                      MenuProps={{ classes: { paper: classes.selectList } }}
                      onChange={(e) => {
                        const oldValue = valuesByFieldName[rawName] ? phrases : JSON.stringify({ defaultValues });
                          return this.setState({
                            lastEdited: name,
                            valuesByFieldName: {
                              ...valuesByFieldName,
                              [rawName]: JSON.stringify({ ...oldValue, voice: e.target.value })
                            }
                          }, () => {
                            onCommit(this.state.valuesByFieldName[rawName]);
                          });
                      }}
                    >
                      { voices.map(voice => <MenuItem key={`voice-${voice}`} className={classes.menuItems} value={voice}>{voice}</MenuItem>)}
                    </Select>
                  </Box>
                  <Box width="40%">
                    <Select id="lang-select" label="Choose Language" className={classes.formElementsSpacing}
                      MenuProps={{ classes: { paper: classes.selectList } }}
                      value={phrases.language || "English"}
                      onChange={(e) => {
                        const oldValue = valuesByFieldName[rawName] ? phrases : JSON.stringify({ defaultValues });
                          return this.setState({
                            lastEdited: name,
                            valuesByFieldName: {
                              ...valuesByFieldName,
                              [rawName]: JSON.stringify({ ...oldValue, language: e.target.value })
                            }
                        }, () => {
                          onCommit(this.state.valuesByFieldName[rawName]);
                        });
                      }}
                    >
                      { languages.map(lang => <MenuItem key={`lang-${lang}`} className={classes.menuItems} value={lang}>{lang}</MenuItem>)}
                    </Select>
                  </Box>
                </Box>


                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch" mt={1}>
                <GenericChooser
                  fullWidth
                  items={Object.keys(JSON.parse(valuesByFieldName[rawName]).phrases || {}).map(key => ({ value: key }))}
                  onChange={(value) => {
                    this.setState({
                      lastEdited: name,
                      valuesByFieldName: {
                        ...valuesByFieldName,
                        [rawName]: JSON.stringify({ ...phrases, selectedPhrase: value })
                      }
                    }, () => {
                      onCommit(this.state.valuesByFieldName[rawName]);
                    });
                  }}
                  value={phrases.selectedPhrase || ""}
                />
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    const oldValue = valuesByFieldName[rawName] ? phrases : JSON.stringify({ defaultValues });
                    const active_phrases = oldValue.phrases || {};
                    active_phrases.default = {
                      prompt: "",
                      localizations: {
                        [phrases.language || "English"]: {
                          text:"", audioID:"", id: uuid()
                        }
                      }
                    };
                    const newValue = { ...oldValue, phrases: active_phrases, selectedPhrase: "default" };
                    this.setState({
                      lastEdited: name,
                      valuesByFieldName: {
                        ...valuesByFieldName,
                        [rawName]: JSON.stringify(newValue)
                      }
                    });
                    onCommit(JSON.stringify(newValue));
                  }}
                  ref={this.iconButtonRef}
                >
                  <Add />
                </IconButton>
                </Box>

              <CustomTextField
                  key={`savePhrase`}
                  label="Phrase Name"
                  disabled={generating}
                  variant="outlined"
                  multiline
                  fullwidth
                  value={ phrases.selectedPhrase || "" }
                  handleChange={(temp) => {
                    const oldValue = JSON.parse(this.state.valuesByFieldName[rawName]);
                    const active_phrases = oldValue.phrases || {};

                    if (temp !== phrases.selectedPhrase) {
                      // if the phrase name already exists, add a unique identifier (4 digit) to the end
                      if (active_phrases[temp]) {
                        temp = `${temp}-temp${Math.floor(1000 + Math.random() * 9000)}`;
                      }
                      active_phrases[temp] = active_phrases[phrases.selectedPhrase];
                      delete active_phrases[phrases.selectedPhrase];
                      return JSON.stringify({ ...oldValue, phrases: active_phrases, selectedPhrase: temp });
                    }
                    return JSON.stringify({ ...oldValue, phrases: active_phrases });
                  }}
                  onCommit={onCommit}
                  className={classes.formElementsSpacing}
                />
                <CustomTextField
                  key={`prompt{index}`}
                  label="Prompt"
                  disabled={generating}
                  variant="outlined"
                  multiline
                  fullwidth
                  value={ phrases.selectedPhrase ? phrases.phrases[phrases.selectedPhrase].prompt : ""}
                  handleChange={(temp) => {
                    const oldValue = JSON.parse(this.state.valuesByFieldName[rawName]);
                    const active_phrases = oldValue.phrases || {};
                    const activePhrase = active_phrases[phrases.selectedPhrase];
                    active_phrases[phrases.selectedPhrase] = {
                      ...activePhrase,
                      localizations: mapValues(activePhrase.localizations, l => ({
                         ...l,
                         textIsStale: true,
                         ...(phraseHasSpeech(activePhrase) && { urlIsStale: true, audioAssetIsStale: true })
                        })),
                      prompt: temp
                    };
                    return JSON.stringify({ ...oldValue, phrases: active_phrases });
                  }}
                  onCommit={onCommit}
                  className={classes.formElementsSpacing}
                />
                <Button
                  variant="outlined"
                  onClick={() => generateAndUpdateText(rawName, phrases, phrases.selectedPhrase)}
                  className={classes.formElementsSpacing}
                >
                  Generate Text
                </Button>
                </div>
                <div className={classes.formElementsSpacing}>
                <CustomTextField
                  key={`text{index}`}
                  label="Text"
                  disabled={generating}
                  variant="outlined"
                  multiline
                  fullwidth
                  value={ (phrases.selectedPhrase && phrases.phrases[phrases.selectedPhrase].localizations[phrases.language]) ? phrases.phrases[phrases.selectedPhrase].localizations[phrases.language].text : ""}
                  handleChange={(temp) => {
                    const oldValue = JSON.parse(this.state.valuesByFieldName[rawName]);
                    const active_phrases = oldValue.phrases || {};
                    const activePhrase = active_phrases[phrases.selectedPhrase];
                    active_phrases[phrases.selectedPhrase].localizations[phrases.language] = {
                      ...activePhrase.localizations[phrases.language],
                      text: temp,
                      textIsStale: false,
                      ...(phraseHasSpeech(activePhrase) && { urlIsStale: true, audioAssetIsStale: true })
                    };
                    return JSON.stringify({ ...oldValue, phrases: active_phrases });
                  }}
                  onCommit={onCommit}
                  className={classes.formElementsSpacing}
                  />
                {/* <InputLabel id="voice-select-label">Choose Voice</InputLabel> */}
                {/* <Button variant="outlined" onClick={() => generateSpeech(rawName, phrases.phrases[phrases.selectedPhrase].localizations[phrases.language].text, phrases.voice, phrases.selectedPhrase, phrases.language)} className={classes.formElementsSpacing}>Generate Speech</Button> */}
                <Button variant="outlined" onClick={() => generateAndUpdateSpeech(rawName, phrases, phrases.selectedPhrase)} className={classes.formElementsSpacing}>Generate Speech</Button>
                {/* show a loading circle if the urlIsLoading is true */}
                { phrases.urlIsLoading
                && <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}>
                    <CircularProgress color="primary" />
                  </div>
                }
                { (speechUnavailable) || (!phrases.urlIsLoading && <AudioPlayer loop={false} download src={`${phrases.phrases[phrases.selectedPhrase].localizations[phrases.language].url}?${new Date().getTime()}` } useStyles={useStyles} />)}

                </div>

              </FormControl>

              {/* <FormControl className={classes.formStyle}>
                  <TextField
                    label="Asset ID List"
                    variant="outlined"
                    multiline
                    fullwidth
                    className={classes.formElementsSpacing}
                    value={this.state.assetIdList}
                    onChange={(e) => {
                      this.setState({ assetIdList: e.target.value });
                    }}
                  />
                  <Button
                    variant="outlined"
                    className={classes.formElementsSpacing}
                    onClick={() => {
                      const assetIds = this.state.assetIdList.split(",");
                      assetIds.forEach(async (assetId) => {
                        // chop off leading and trailing spaces if any
                        assetId = assetId.trim();
                        // chop off the leading and trailing ' character
                        assetId = assetId.substring(1, assetId.length - 1);
                        await createAssetElement(assetId, this.elementsRef, this.assetsRef, assets);
                      });
                    }}
                  >
                    Generate Assets
                  </Button>

              </FormControl> */}

              {/* create a loading layer for the full screen with inline style */}
              {this.state.isLoading
                && <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  zIndex: 1000,
                }}>
                  {/* <CircularProgress color="primary" /> */}
                </div>
              }

            </div>
          );
        }
        default: {
          log.error(`Field type ${type} not handled`);
          return null;
        }
      }
    });

    return <Box className={classes.inputFieldsContainer}>{renderers}</Box>;
  }
}


const mapStateToProps = state => ({
  app: getAppInfo(state),
  appId: getAppInfo(state).id,
  scenes: getScenes(state),
  assets: getAllAssetsById(state),
  scripts: getAllScripts(state)
});

const mapDispatchToProps = (dispatch, { sceneId, element }) => ({
  /**
   * Creates an element.
   */
  findOrCreateLocalizedPhraseElement: async ({
    phrases, phraseName, language, elementsRef, assets, assetsRef, onUpdateElement
  }) => {
    const phrase = phrases.phrases[phraseName];
    const localization = phrase.localizations[language];
    const elt = someElement(elementsRef.current,
                    e => e.type === ElementTypes.ASSET
                          && (e.schema.strings.assetSrc === localization.audioID || e.schema.strings.localizedPhraseId === localization.id));
    if (elt) return elt.id;
    try {
      const nodeId = uuid();

      // Mark this element as phrasesElement
      onUpdateElement(createSchemaUpdate({ type: ActionSchemaTypes.BOOL, name: 'isPhrasesElement', value: true }));

      // get the list of audio mixing schema items in the element and pass it to the new element
      let audioMixingSchemaStrings = {};
      let audioMixingSchemaBools = {};
      let audioMixingSchemaFlots = {};
      if (element.schema.strings) {
        audioMixingSchemaStrings = Object.fromEntries(
          Object.entries(element.schema.strings).filter(([key, _]) => key.startsWith('audio.'))
        );
      }
      if (element.schema.bools) {
        audioMixingSchemaBools = Object.fromEntries(
          Object.entries(element.schema.bools).filter(([key, _]) => key.startsWith('audio.'))
        );
      }
      if (element.schema.floats) {
        audioMixingSchemaFlots = Object.fromEntries(
          Object.entries(element.schema.floats).filter(([key, _]) => key.startsWith('audio.'))
        );
      }

      const schema = {
          strings: {
            'phrase name': phraseName,
            language,
            localizedPhraseId: localization.id,
            ...audioMixingSchemaStrings
          },
          bools: {
            visible: false,
            isPhrasesChild: true,
            ...audioMixingSchemaBools
          },
          floats: {
            ...audioMixingSchemaFlots
          }
      };
      await txns.request(sceneId, createFullElementAction(element.id, schema, ElementTypes.ASSET, nodeId, true, false));
      const newElement = elementById(elementsRef.current, nodeId);
      if (newElement) {
        let newAsset = assets[localization.audioID];
        const newAssetRef = assetsRef.current[localization.audioID];
        if (!newAsset) { // Asset isn't here yet. Fake it. TODO: Is this too fragile
          newAsset = {
            id: localization.audioID,
            name: `${lang_codes[phrases.language]}~${phraseName}`
          };
        }
        console.log(newAsset);
        console.log(newAssetRef);
        dispatch(
          addAssetToElement({
            sceneId,
            element: newElement,
            asset: newAsset,
            undefined
          })
        );
      }
      return nodeId;
    } catch (exception) {
      console.error(`could not create element: ${exception}`);
      return undefined;
    }
  },

  // Create and publish an asset given the asset ID
  createAssetElement: async (assetId, elementsRef, assetsRef, assets) => {
    const nodeId = uuid();
    const schema = {
      strings: {
        assetSrc: assetId,
        name: "Asset"
      },
      bools: {
        visible: false
      }
    };
    await txns.request(sceneId, createFullElementAction(element.id, schema, ElementTypes.ASSET, nodeId, true, false));

    const newElement = elementById(elementsRef.current, nodeId);
    if (newElement) {
      let newAsset = assets[assetId];
      const newAssetRef = assetsRef.current[assetId];
      if (!newAsset) {
        newAsset = {
          id: assetId,
        };
      }
      console.log(newAsset);
      console.log(newAssetRef);
      dispatch(
        addAssetToElement({
          sceneId,
          element: newElement,
          asset: newAsset,
          undefined
        })
      );
    }
    return nodeId;
  }

});


export default withStyles(theme => ({
  inputFieldsContainer: {
    '& > *': {
      marginTop: theme.spacing(2)
    }
  },
  labeledInput: {
    width: '100%',
    paddingRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  formStyle: {
    margin: theme.spacing(1)
  },
  formElementsSpacing: {
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  selectList: {
    maxHeight: '300px',
  },
  menuItems: {
    width: '100%',
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'gray'
    },
    '& a': {
      textDecoration: 'none',
      color: 'white',
    }
  },
}))(withRouter(connect(mapStateToProps, mapDispatchToProps)(VariableList)));
