const ActionSchemaTypes = {
  STRING: 'string',
  INT: 'int',
  FLOAT: 'float',
  BOOL: 'bool',
  VEC3: 'vec3',
  COL4: 'col4',
  PHRASES: 'phrases',
  AUDIOMIXING: 'audioMixing'
};

export const isValidSchemaType = schemaType => Object.values(ActionSchemaTypes).includes(schemaType);
export default ActionSchemaTypes;
