import { createSelector } from 'reselect';
import { getScriptTagsArray, isProbablyGuid, newCategory } from '../../util/util';
import { getScene } from './scenesSelectors';
import ElementTypes from '../../constants/ElementTypes';
import LibraryTypes from '../reducers/scriptLibrariesReducer';
import { getAppInfo } from './appSelectors';
import { getSelectedScriptCategoryName, getUsageFilter } from './uiSelectors';
import { getMyApps } from './userSelectors';
import { UsageTypes } from '../reducers/uiReducer';

export const getLibraries = state => state.scripts.libraries;

export const getAllScriptsById = createSelector(getLibraries, _libraries => Object.entries(_libraries).reduce((acc, [, library]) => ({ ...acc, ...library }), {}));

export const getAllScripts = createSelector(getAllScriptsById, scriptsById => Object.entries(scriptsById).map(([, script]) => script));

export const getSelectedCategoryScripts = createSelector(
  getLibraries,
  getAllScripts,
  getSelectedScriptCategoryName,
  getAppInfo,
  (libs, scripts, category, { id: appId }) => {
    // Generally speaking, we can do a search of a script's tags. However,
    // there are also several special categories that correspond directly
    // to a specific library.
    if (Object.entries(LibraryTypes).find(([, libraryType]) => libraryType === category)) {
      return libs[category];
    }

    // If a category name is an guid, then filter based on those.
    // This is not a proper check for a guid-- but also it doesn't
    // really matter, we just want a quick out.
    if (isProbablyGuid(category)) {
      return libs[category];
    }

    // If the category is __app__, then use the current app
    if ((category === '__app__' || category === appId) && libs[appId]) {
      return libs[appId];
    }

    // TODO: fix underlying issue: we are using name instead of URL
    if (category === 'public') {
      // eslint-disable-next-line no-underscore-dangle
      return libs.__public__;
    }

    // If it's not a special tag, we can do a simple filter.
    return scripts
      .filter(script => script.tags.toLowerCase().includes(category))
      .reduce((acc, script) => ({ ...acc, [script.id]: script }), {});
  }
);

// Takes an optional category to get the scripts for that category
export const createGetSelectedCategoryScripts = (optionalCategory = null) => createSelector(
  getLibraries,
  getAllScripts,
  getSelectedScriptCategoryName,
  getAppInfo,
  (libs, scripts, selectedCategory, { id: appId }) => {
    const category = optionalCategory || selectedCategory;

    // Special category check for libraries
    if (Object.entries(LibraryTypes).find(([, libraryType]) => libraryType === category)) {
      return libs[category];
    }

    // Check if category is a GUID
    if (isProbablyGuid(category)) {
      return libs[category];
    }

    // Check if category is for the current app
    if ((category === '__app__' || category === appId) && libs[appId]) {
      return libs[appId];
    }

    // Check if category is public
    if (category === 'public') {
      return libs.__public__;
    }

    // Default case: filter by tag
    return scripts
      .filter(script => script.tags.toLowerCase().includes(category))
      .reduce((acc, script) => ({ ...acc, [script.id]: script }), {});
  }
);


export const getFilteredScriptsById = createSelector(
  getScene,
  getSelectedCategoryScripts,
  getUsageFilter,
  (scene, scriptsById, usageFilter) => {
    if (usageFilter === UsageTypes.All) {
      return scriptsById;
    }

    const usedScriptsById = {};
    const unusedScriptsById = { ...scriptsById };

    const search = ({ type, schema, children = [] }) => {
      if (type === ElementTypes.CONTENT && schema && schema.strings && schema.strings.scripts) {
        const scripts = JSON.parse(schema.strings.scripts) || [];
        for (const { id } of scripts) {
          const script = scriptsById[id];
          if (script) {
            usedScriptsById[id] = script;
          }

          delete unusedScriptsById[id];
        }
      }

      children.forEach(child => search(child));
    };

    search(scene.elements);

    return usageFilter === UsageTypes.InUse ? usedScriptsById : unusedScriptsById;
  }
);

export const getFilteredScripts = createSelector(getFilteredScriptsById, byId => byId ? Object.entries(byId).map(([, script]) => script) : undefined);

export const getDefaultCategoriesByUrl = state => ({
  [LibraryTypes.PUBLIC]: newCategory('Public', LibraryTypes.PUBLIC)
});

export const getDefaultCategories = createSelector(getDefaultCategoriesByUrl, defaultCategoriesByUrl => Object.entries(defaultCategoriesByUrl).map(([, category]) => category));

export const getTags = createSelector(getAllScripts, getDefaultCategoriesByUrl, (scripts, defaultCategoriesByUrl) => Object.entries(
    scripts.reduce(
      (categoriesByUrl, { tags: tagsString }) => getScriptTagsArray(tagsString).reduce(
          (_categoriesByUrl, tag) => (!_categoriesByUrl[tag] && !defaultCategoriesByUrl[tag]
              ? {
                  ..._categoriesByUrl,
                  [tag]: true
                }
              : _categoriesByUrl),
          categoriesByUrl
        ),
      {}
    )
  ).map(([tag]) => tag));

const getTaggedDefaultCategories = createSelector(getDefaultCategories, getTags, (categories, tags) => tags.reduce((acc, tag) => [...acc, newCategory(tag)], categories));

export const getCategories = createSelector(
  getTaggedDefaultCategories,
  getMyApps,
  getAppInfo,
  (categories, apps, info) => {
    const cats = categories.concat(
      apps.map(({ id, name }) => {
        const cat = newCategory(name, id);
        cat.name = `__app__.${cat.name}`;
        return cat;
      })
    );
    cats.sort((a, b) => (a.url === info.id ? -1 : 0));

    return cats;
  }
);
