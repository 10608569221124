import { AsyncStatus, getActions } from '@enklu/server-api';
import {
 Box, Grid, Typography, isWidthDown, withWidth
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { orderBy, chunk } from 'lodash';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

import { funcNewMonitor } from '../../util/util';
import { getAppInfo } from '../../store/selectors/appSelectors';
import { getLastAppId } from '../../store/selectors/preferencesSelectors';
import { getMyApps } from '../../store/selectors/userSelectors';
import { initializeUntitledApp, loadApp } from '../../store/actions/initializeActions';
import { pushNavState } from '../../store/actions/navActions';
import ConfirmationModal from '../modals/MuiConfirmationModal';
import ModalTypes from '../../constants/ModalTypes';
import MyExperiencesGridItem from './MyExperiencesGridItem';
import RenameExperienceModal from '../modals/MuiRenameExperienceModal';
import MuiInviteCollaboratorsModal from '../modals/MuiInviteCollaboratorsModal';
import ExpHoloLogin from '../modals/MuiMyExpHoloLoginModal';

const { deleteapp, createapp, getmyapps } = getActions('trellis');

const useGridStyles = makeStyles(theme => ({
  pagination: {
    marginTop: theme.spacing(2)
  },
  errorColor: { color: theme.palette.error.main }
}));

const MyExperiencesGrid = ({
  sortByValue,
  filterValue,
  apps,
  getApps,
  width,
  onLoadApp,
  history,
  deleteApp,
  duplicateApp
}) => {
  const classes = useGridStyles();
  const [pageValue, setPageValue] = React.useState(1);
  const [perPage, setPerPage] = React.useState(8);

  const getPerPage = () => {
    if (isWidthDown('sm', width)) {
      return 4;
    }
    if (isWidthDown('md', width)) {
      return 6;
    }
    if (isWidthDown('lg', width)) {
      return 8;
    }
    return 18;
  };

  React.useEffect(() => {
    getApps();
  }, []);

  React.useEffect(() => {
    setPerPage(getPerPage());
  }, [width]);

  React.useEffect(() => {
    setPageValue(1);
  }, [sortByValue, filterValue, perPage]);

  const filteredSortedApps = orderBy(
    apps.filter(app => !filterValue || app.name.toLowerCase().includes(filterValue.toLowerCase())),
    [app => (sortByValue === 'updatedAt' ? app.updatedAt : app.name.toLowerCase())],
    [sortByValue === 'updatedAt' ? 'desc' : 'asc']
  );

  const paginatedApps = chunk(filteredSortedApps, perPage);

  const [appToRename, setAppToRename] = React.useState(null);
  const [appToDelete, setAppToDelete] = React.useState(null);
  const [appToDuplicate, setAppToDuplicate] = React.useState(null);
  const [appToShare, setAppToShare] = React.useState(null);
  const [appToQR, setAppToQR] = React.useState(null);
  const { status: deleteStatus, runAction: runDeleteAction } = funcNewMonitor(deleteApp);
  const { status: duplicateStatus, runAction: runDuplicateAction } = funcNewMonitor(duplicateApp);

  const moreMenuItems = app => (canDelete, canEdit) => {
    const items = [
      {
        title: 'Duplicate',
        customOnItemClick: () => {
          setAppToDuplicate(app);
        }
      }
    ];
    items.push({
      title: "HoloLogin",
      customOnItemClick: () => {
        setAppToQR(app);
      }
    });
    items.push({
      title: "Share Experience",
      customOnItemClick: () => {
        setAppToShare(app);
      }
    });
    if (canEdit) {
      items.push({
        title: 'Edit Details',
        customOnItemClick: () => {
          setAppToRename(app);
        }
      });
    }

    if (canDelete) {
      items.push({
        title: 'Delete',
        customOnItemClick: () => {
          setAppToDelete(app);
        },

        className: classes.errorColor
      });
    }
    return items;
  };

  return (
    <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
      <RenameExperienceModal
        app={appToRename}
        onClose={() => {
          setAppToRename(null);
        }}
      />
      { appToShare && (
        <MuiInviteCollaboratorsModal
          app={appToShare}
          onClose={() => {
            setAppToShare(null);
          }}
          shareable={false}
        />
      )}
      <ExpHoloLogin
        app = {appToQR}
        onClose={() => {
            setAppToQR(null);
        }}
        open = {!!appToQR}
      />
      <ConfirmationModal
        title={'Delete Experience'}
        confirmIsDanger={true}
        message={`Are you sure you want to delete '${appToDelete ? appToDelete.name : ''}'? This cannot be undone.`}
        isLoading={deleteStatus === AsyncStatus.IN_PROGRESS}
        onClose={() => setAppToDelete(null)}
        onConfirm={() => runDeleteAction({
            params: [{ appId: appToDelete.id }],
            onFinally: () => {
              setAppToDelete(null);
            }
          })
        }
        open={!!appToDelete}
      />
      <ConfirmationModal
        title={'Duplicate'}
        message={`Are you sure you want to duplicate '${appToDuplicate ? appToDuplicate.name : ''}'?`}
        isLoading={duplicateStatus === AsyncStatus.IN_PROGRESS}
        onClose={() => {
          if (sortByValue === 'updatedAt') {
            setPageValue(1);
          }

          setAppToDuplicate(null);
        }}
        onConfirm={() => runDuplicateAction({
            params: [{ app: appToDuplicate }],
            onFinally: () => {
              setAppToDuplicate(null);
            }
          })
        }
        open={!!appToDuplicate}
      />

      {filteredSortedApps.length > 0 ? (
        <Box
          width={'100%'}
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container spacing={4}>
            {paginatedApps[pageValue - 1]
              && paginatedApps[pageValue - 1].map(app => (
                <Grid key={app.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <MyExperiencesGridItem
                    onLoadApp={onLoadApp}
                    history={history}
                    app={app}
                    getMenuItems={moreMenuItems(app)}
                  />
                </Grid>
              ))}
          </Grid>
          <Pagination
            className={classes.pagination}
            count={paginatedApps.length}
            page={pageValue}
            onChange={(_event, value) => {
              setPageValue(value);
            }}
            variant="outlined"
          />
        </Box>
      ) : (
        <Typography variant="h6">No Results Found</Typography>
      )}
    </Box>
  );
};

MyExperiencesGrid.propTypes = {
  sortByValue: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,

  // connect
  lastAppId: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  apps: PropTypes.array.isRequired,

  getApps: PropTypes.func.isRequired,
  deleteApp: PropTypes.func.isRequired,
  duplicateApp: PropTypes.func.isRequired,
  onLoadApp: PropTypes.func.isRequired,
  onInitializeUntitledApp: PropTypes.func.isRequired,

  // withRouter
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,

  classes: PropTypes.object,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  lastAppId: getLastAppId(state),
  appId: getAppInfo(state).id,
  apps: getMyApps(state)
});

const mapDispatchToProps = dispatch => ({
  getApps: () => dispatch(getmyapps()),

  onInitializeUntitledApp: ({ history: appHistory }) => dispatch(initializeUntitledApp({ history: appHistory })),

  deleteApp: ({ appId }) => dispatch(deleteapp({ appId })).then(() => dispatch(getmyapps())),

  duplicateApp: ({ app }) => dispatch(
      createapp({
        name: `Copy of ${app.name}`,
        description: app.description,
        appId: app.id
      })
    ).then(() => dispatch(getmyapps())),

  onLoadApp: async (appId, history) => {
    await dispatch(loadApp({ appId }));
    history.push({ pathname: '/' });
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withWidth()(MyExperiencesGrid)));
