const AudioChannleList = [
  {
    displayName: 'All',
    value: 'master'
  },
  {
    displayName: 'Music',
    value: 'music'
  },
  {
    displayName: 'SFX',
    value: 'sfx'
  },
  {
    displayName: 'UI',
    value: 'ui'
  },
  {
    displayName: 'Environment',
    value: 'environment'
  },
  {
    displayName: 'Voice',
    value: 'voice'
  },
  {
    displayName: 'Guide',
    value: 'guide'
  },
  {
    displayName: 'None',
    value: 'none'
  }
];

export default AudioChannleList;
